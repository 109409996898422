<template>
  <div class="login">
    <div v-if="isPC" class="pcBg">
      <div class="login-img">
        <!-- <img
          src="https://sitelu.oss-cn-hangzhou.aliyuncs.com/assets/kuailehudong_orgrization/image/loginChildBg.png"
          alt=""
        /> -->
      </div>
      <div class="pcLogin">
        <span class="login-title">登录</span>
        <div class="pcPhone">
          <div class="tip">手机号</div>
          <el-input
            v-model="phone"
            placeholder="请输入手机号"
            clearable
            @input="
              () => {
                if (phone.length > 11) phone = phone.slice(0, 11)
              }
            "
            type="number"
          ></el-input>
        </div>
        <div class="tip">验证码</div>
        <div class="pcCode">
          <el-input
            placeholder="请输入验证码"
            v-model="pwd"
            @keyup.enter.native="sign"
          >
          </el-input>
          <div class="code" @click="yzm1">
            <div>{{ yzm }}</div>
          </div>
        </div>
        <button @click="sign()" :class="!disableLogin ? 'pcBtn' : 'doLogin'">
          登录
        </button>
      </div>
    </div>
    <div class="login-content" v-else>
      <div class="img"></div>

      <h1>市场经理管理系统</h1>

      <p class="edition">V1.0.0</p>

      <p class="mode">账号登录</p>

      <el-input
        v-model="phone"
        placeholder="请输入账号"
        maxlength="11"
      ></el-input>

      <div class="yzm">
        <el-input
          v-model="pwd"
          placeholder="请输入验证码"
          show-password
          @keyup.enter.native="sign"
        ></el-input>

        <el-button @click="yzm1" :disabled="disabled">{{ yzm }}</el-button>
      </div>

      <el-button type="primary" class="login" shape="circle" @click="sign()"
        >登录</el-button
      >
    </div>
  </div>
</template>



<script>
import { post_login, find_user, user_login } from '../api/login.js'

export default {
  name: 'login',

  data() {
    return {
      phone: '', //输入的手机号

      pwd: '', //输入的验证码

      yzm: '获取验证码',

      disabled: false,

      time: 60,

      show: false,

      isPC: true,

      disableLogin: false,

      showPhone: false,

      showCode: false
    }
  },
  watch: {
    phone(newVal) {
      if (newVal) {
        this.showPhone = true
        if (this.pwd) {
          this.disableLogin = true
        }
      } else {
        this.showPhone = false
      }
    },
    pwd(newVal) {
      if (newVal) {
        this.showCode = true
        if (this.phone) {
          this.disableLogin = true
        }
      } else {
        this.showCode = false
      }
    }
  },
  methods: {
    //验证码

    yzm1() {
      post_login({ phone: this.phone }).then((res) => {
        if (res.code == 200) {
          this.time = 60
          this.timer()
          this.$message({
            message: '获取验证码成功',
            type: 'success'
          })
        }
      })
    },

    //验证码

    timer() {
      if (this.time > 0) {
        this.disabled = true

        this.time--

        this.yzm = this.time + '秒'

        setTimeout(this.timer, 1000)
      } else {
        this.time = 0

        this.yzm = '重新获取'

        this.disabled = false
      }
    },

    //登录事件

    sign() {
      sessionStorage.setItem('phone', this.phone)

      if (this.phone === '') {
        this.$message.error('请输入手机号')
      } else if (this.pwd === '') {
        this.$message.error('请输入验证码')
      } else {
        user_login({ phone: this.phone, code: this.pwd }).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: 'success'
            })

            find_user({ token: res.data }).then((resc) => {
              if (resc.data.perms == 'system') {
                sessionStorage.setItem('Permission', '/OrderManagement')
              } else if (resc.data.perms == 'user') {
                sessionStorage.setItem('Permission', '/user')
              }

              sessionStorage.setItem('marketName', resc.data.marketName)

              sessionStorage.setItem('perms', resc.data.perms)

              sessionStorage.setItem('token', res.data)

              sessionStorage.setItem('code', 0)

              location.reload()
            })
          }
        })
      }
    }
  },
  created() {
    console.log(navigator);
    const sUserAgent = navigator.userAgent.toLowerCase()
    const bIsIpad = sUserAgent.match(/ipad/i) == 'ipad'
    const bIsIphoneOs = sUserAgent.match(/iphone os/i) == 'iphone os'
    const bIsMidp = sUserAgent.match(/midp/i) == 'midp'
    const bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == 'rv:1.2.3.4'
    const bIsUc = sUserAgent.match(/ucweb/i) == 'ucweb'
    const bIsAndroid = sUserAgent.match(/android/i) == 'android'
    const bIsCE = sUserAgent.match(/windows ce/i) == 'windows ce'
    const bIsWM = sUserAgent.match(/windows mobile/i) == 'windows mobile'
    if (
      bIsIpad ||
      bIsIphoneOs ||
      bIsMidp ||
      bIsUc7 ||
      bIsUc ||
      bIsAndroid ||
      bIsCE ||
      bIsWM
    ) {
      this.isPC = false
    } else {
      this.isPC = true
    }
  }
}
</script>



<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="less">
//弹框颜色

::v-deep .ivu-message-notice-content {
  background: #ffb8bb !important;

  color: #e51c23 !important;
}

.login {
  display: flex;

  justify-content: center;

  align-items: center;

  height: 100vh;

  .login-content {
    width: 380px;

    height: 550px;

    text-align: center;

    box-shadow: 0px 0px 16px 1px rgba(41, 198, 243, 100);

    border: 1px solid rgba(255, 0, 0, 0);

    padding: 18px 25px 0 25px;

    .img {
      width: 80px;

      height: 80px;

      background: url('../../public/images/logo.png');

      background-size: 100% 100%;

      box-shadow: 0px 2px 6px 0px rgba(79, 249, 255, 100);

      border-radius: 50%;

      margin: 14px auto;
    }

    h1 {
      line-height: 41px;

      color: rgba(23, 120, 252, 100);

      font-size: 28px;

      font-family: Roboto;
    }

    .edition {
      line-height: 23px;

      color: rgba(136, 136, 136, 100);

      font-size: 16px;

      font-family: Roboto;
    }

    .mode {
      line-height: 29px;

      opacity: 0.5;

      color: rgba(38, 50, 56, 100);

      font-size: 20px;

      font-family: Roboto;
    }

    .yzm {
      position: relative;

      ::v-deep button {
        position: absolute;

        top: 50%;

        right: 10px;

        transform: translate(0, -50%);
      }
    }

    ::v-deep .el-input {
      padding-bottom: 15px;

      padding-top: 15px;

      height: auto;
    }

    ::v-deep .el-input:focus {
      outline: 0;

      box-shadow: 0 5px 5px -5px rgba(45, 140, 240, 0.2);
    }

    .login {
      margin: 50px auto 0 auto;

      width: 300px;

      height: 50px;

      opacity: 0.56;

      background-color: rgba(23, 120, 252, 100);

      color: rgba(255, 255, 255, 100);

      font-size: 20px;

      box-shadow: 0px 2px 6px 0px rgba(23, 120, 252, 100);

      font-family: Microsoft Yahei;
    }
  }
}

.el-button {
  border: 0px solid #dcdfe6 !important;
}

.pcBg {
  width: 100vw;
  height: 100vh;
  display: flex;
}

.login-img {
  width: 5.7708rem;
  height: 100%;
  margin-right: 0.349rem;
  background: url(https://sitelu.oss-cn-hangzhou.aliyuncs.com/assets/kuailezhanye/market/image/banner.png)
    no-repeat;
  background-size: 100% 100%;
}

/* .login-img img {
  width: 3.7708rem;
  height: 100vh;
} */

.pcLogin {
  width: 3.0208rem;
  height: 3.2083rem;
  margin-top: 1.2292rem;
  padding: 0 0.2604rem;
}

.pcLogin img {
  width: 1.6094rem;
  height: 0.5729rem;
}

.pcPhone {
  margin-top: 0.3438rem;
}

.pcCode {
  position: relative;
}

/deep/ .el-input__inner {
  // height: 0.3958rem;
  // border: 0;
  padding-left: 5px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ .pcBg .el-input__inner {
  height: 0.3958rem !important;
  border: 1px solid #d8d8d8;
  font-size: 21px;
}

/deep/ input[type='number'] {
  -moz-appearance: textfield !important;
}

.code {
  background-color: transparent;
  color: #0088f4;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.0625rem;
  border: 0;
  height: 100%;
}

.code div {
  border-left: 1px solid  #D8D8D8;
  height: 0.125rem;
  padding: 0 0.1042rem 0 0.0625rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pcBtn {
  width: 100%;
  height: 0.3333rem;
  background: #0088F4FF;
  border: 0;
  margin-top: 0.2604rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.1042rem;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  box-shadow: 0px 4px 8px 0px #d7efff;
  border-radius: 8px;
}

.doLogin {
  width: 100%;
  height: 0.3333rem;
  background: #457bf3;
  border: 0;
  margin-top: 0.2604rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.1042rem;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
}

.tip {
  margin-top: 0.2604rem;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0.0677rem;
}

.login-title {
  font-size: 42px;
  border-bottom: 0.0208rem solid #0088f4;
  padding-bottom: 0.026rem;
}
</style>

